import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function Directors() {
  /* Slider */
  // const DirSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Appointment and Resignation of Director`,
  //     content: "",
  //     image: "/imgs/business/responsible.png",
  //     alt_tag: "Appointment and Resignation of Director",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Appointment And Resignation Of Directors`,
    buyBtnLink: `#pricing-buy`,
    price: `2999`,
    content: [
      {
        points: `Share Certificate
        Book`,
        icon: "/imgs/icons/bregistration/form.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Minutes Book &
        Statutory Register`,
        icon: "/imgs/icons/bregistration/itr.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
      },
    ],
  };
  /* About Data With Form*/
  const DireAboutData = {
    header: `Appointment and Resignation of Director`,
    sub_title: `Advisory service for appointment and resignation of director`,
    content_paragraph: [
      `Directors are the heart and soul of any organization. As the
        company's top workers, they oversee, direct, and manage all
        activities and services. Both the incumbent director's
        resignation and the selection of a new director are options
        for the appointment, resignation, or replacement of
        directors.`,

      // `Directors are members of the management team who oversee,
      //   direct, and coordinate business operations. The goal of
      //   director rotation is to provide the best possible balance of
      //   shareholders and expertise.`,

      // `Whether it be an appointment,
      //   resignation, or removal, if the change does not immediately
      //   take effect, MCA is notified.`,
    ],
  };
  /* Scroll Nav Data */
  const DirScrollId = [
    {
      id: `#types`,
      heading: `Types`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#qualifications`,
      heading: `Qualifications`,
    },
  ];

  /* Img Content Component Data */
  const DirIcData = {
    id: "types",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Various director types appointed by the company",
    image: "/imgs/registration/msme/msme.png",
    alt_tag: "Various director types appointed by the company",
    Paragraph: "",
    points: [
      {
        head: `Managing Director`,
        content: `They are given
      complete authority and charge over the whole
      functioning of the Company.`,
        icon: true,
      },
      {
        head: `Executive Director`,
        content: `Manage the
      Company's day-to-day operations and are more
      accountable and efficient for the Company.`,
        icon: true,
      },
      {
        head: `Nominee Director`,
        content: `This type of directors are not the
      principal directors, but are chosen to represent the
      interests of the PE/VC investors or banks that have
      provided loans, or shareholders in the event of a
      recognized company.`,
        icon: true,
      },
      {
        head: `Independent Director`,
        content: `In this directors are
      recruited within the organization to oversee and
      ensure good governance.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const DirCfData = {
    id: "procedure",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Procedure For Director Appointment",
    content: ``,
    body: [
      {
        head: "Selection Of The First Directors",
        points: `The initial directors of the company are individuals
      who are eligible to hold the position at the time the
      company registers with the MCA. In addition, if there
      are any directors throughout the registration process,
      the original signatories of the MOA will automatically
      list them.`,
        icon: true,
      },
      {
        head: "Requirements: What Must Be Done?",
        points: `+MCA has announced a new, straightforward procedure
      for company incorporation. The Director Identification
      Number, or DIN, is not a prerequisite for becoming a
      director. It is essential to include all of the
      directors' information in the e-form, as suggested by
      MCA.`,
        icon: true,
      },
      {
        head: "Director's resignation in accordance with Section 168",
        points: `After the business's general meeting, the company
      shall publish a report of directors containing the
      resignation notice.`,
        icon: true,
      },
      {
        points: `Within a month or 30 days of resigning, the director
      must also recommend and send a copy of the
      resignation to the Registrar along with a detailed
      letter of resignation from the post of Director of
      the company.`,
        icon: true,
      },
      {
        points: `Company
      director's resignation should be effective as of the
      time or date the company granted his notice, or as
      of the itemized period the director stated in mind,
      whichever is later. As long as the firm director who
      resigned is still held accountable for any offences
      that occurred during his term, even after his
      retirement.`,
        icon: true,
      },
      {
        points: `Directors may all quit at once, and the
      promoter or the central government will choose the
      required number of directors. Old directors of the
      corporation must continue serving until new ones are
      elected or until the company nominates new directors
      at the called general meeting`,
        icon: true,
      },
      {
        head: "Understandings underlying Directors' Resignations",
        points: `Argument before the board, Better career opportunities, Misuse in business matters,
        Suspension because of a violation, The falloff in nomination.`,
        icon: true,
      },
    ],
  };
  /* Slider */
  const imgSlider = [
    "/imgs/assited/invoice-billing.png",
    "/imgs/registration/msme/msme-reg.png",
    "/imgs/assited/acc-rec-monitoring.png",
  ];

  /*  Slider Content Component Data */
  const DirScData = {
    id: "documents",
    background: [],
    mt_div: "mt-3",
    mt_img: "mt-5",
    header: "Required documents for appointment and resignation of directors",
    paragraph: `The 31st GST Council Meeting stated that taxpayers would also receive the GST returns. For the 
      ease of filing by taxpayers registered for the GST, this return framework will include simple return 
      structures. Except for small taxpayers, there will be one major return, GST RET-1 and 2 annexures, GST ANX-1
       and GST ANX-2, to be presented each month (turnover up to Rs 5 crore in the former money related year who 
        can select to record a similar quarterly).`,
    content: [
      {
        points: `PAN Card and the Director's Photo will be Designated.`,
        icon: true,
      },
      {
        points: `Evidence of residency and identity, such as the
          director-to-Aadhar be's card, voter ID, passport, or
          driver's license.`,
        icon: true,
      },
      {
        points: `For the Director to be terminated or removed, a
          digital signature certificate, or DSC, of the current
          Director must be shown.`,
        icon: true,
      },
      {
        points: `In the event that the form is acknowledged, the notice
          of resignation is submitted to the company.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const DirCiData = {
    id: "qualifications",
    background: "main-content",
    mt_div: "mt-3",
    header: "Qualifications for Directorship",
    paragraph: ``,
    points: [
      {
        head: `Eligibility`,
        content: `Establishment of nationality`,
        icon: true,
      },
      {
        content: `Demarcation of age`,
        icon: true,
      },
      {
        content: `DIN required`,
        icon: true,
      },
      {
        content: `Maximum number of valid directors`,
        icon: true,
      },
      {
        head: `Ineligibility`,
        content: `Insane or a bankrupt individual`,
        icon: true,
      },
      {
        content: `Criminal history`,
        icon: true,
      },
      {
        content: `Awaiting delinquent returns`,
        icon: true,
      },
    ],
    image: "/imgs/registration/msme/msme-doc.png",
    alt_tag: "Required Qualifications for Directorship",
  };
  var FAQ_data = [
    {
      header:
        "If all of the directors leave or resign, who will run everything?",
      body: [
        {
          content: `If this is the case, the promoter or impresario will assume
          command. If there is no promoter, the central government will
          provide temporary directors to run the Company's operations until
          new directors are appointed.`,
          icon: false,
        },
      ],
    },

    {
      header: "Can a Director resign from the Board of Directors?",
      body: [
        {
          content: `Yes, a director can resign from the company.`,
          icon: false,
        },
      ],
    },
    {
      header: "Should I reapply if I already have a DIN?",
      body: [
        {
          content: `No, a DIN, or 'Director Identification Number,' is assigned for
          life and can thus be used indefinitely.`,
          icon: false,
        },
      ],
    },
    {
      header: "Can a company keep an NRI or foreigner as a director?",
      body: [
        {
          content: `Yes, it is possible however, they must have a DIN and a valid
          passport. If that person does not have a DIN, they must apply
          through the Company if they want to be a Director of the company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "Is there a specific requirement for the appointment of directors?",
      body: [
        {
          content: ` No, there is no specific requirement for the appointment of
          directors.`,
          icon: false,
        },
      ],
    },
    {
      header: "Can 'Director's resignation be rejected?",
      body: [
        {
          content: `According to Section 168 of the Companies Act 2013, the
          administration does not have the authority to reject a director's
          resignation.`,
          icon: false,
        },
      ],
    },
    {
      header: "Who can be a Director in a Company?",
      body: [
        {
          content: `An individual or a living person can be appointed as a 
          Director in a company, an entity or a body corporate cannot be appointed as the director of a company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "What are the eligibility criteria to be a Director in the company?",
      body: [
        {
          content: `Yes, there are certain protocols which are as follows: The proposed individual has to be a major
           He or she has to qualify under the law mentioned under the Companies Act,2013 The members of the board 
           should agree to the appointment of the new director`,
          icon: false,
        },
      ],
    },
    {
      header: "Does a Director get compensation even after his removal?",
      body: [
        {
          content: `Even after the Director is removed by the company he is entitled to get the compensation damaged that are payable to him.`,
          icon: false,
        },
      ],
    },
    {
      header: "Can a Director of a Company be removed without his consent?",
      body: [
        {
          content: `Yes, a Director of a company can be removed without his consent under certain circumstances.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Appointment and Resignation of Director'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={DirSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={DireAboutData} />

          <Counter />
          <ScrollNav scroll_data={DirScrollId} />
          <ImgContent item={DirIcData} />
          <ContentForm CFSection_data={DirCfData} />
          <SliderContent ScSection_data={DirScData} />
          <ContentImg CISection_data={DirCiData} />

          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
